import React, { Component } from "react";
import Iframe from 'react-iframe'


export class RSVP extends Component {

    render() {
        return (
            <div className="container justify-content-center" >
                <div className="">
                    <Iframe src="https://docs.google.com/forms/d/e/1FAIpQLSee70ShZko-6Etg6DFy9r3KdDfk8Y1drjXxtz8aRODka7AnSw/viewform?embedded=true" width="100%" height="1034" frameborder="1" marginheight="0" marginwidth="0">Loading…</Iframe>
                </div>
            </div>
        );
    }
}