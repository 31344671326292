import React, { Component } from "react";

window.onscroll = function () {
    scrollFunction();
};
function scrollFunction() {
    if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
    ) {
        document.getElementById("myBtn").style.display = "block";
    } else {
        document.getElementById("myBtn").style.display = "none";
    }
}
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export class ScrollTop extends Component {
    render() {
        return (
            <div>
                <button onClick={topFunction} id="myBtn" title="Go to top">
                    <i className="fa fa-chevron-up" />
                </button>
            </div>
        );
    }
}