import React, { Component } from "react";
import { Button, Container } from "reactstrap";

export class Gifts extends Component {
  static displayName = Gifts.name;

  // componentDidMount() {
  //   const script = document.createElement("script");

  //   script.src = "https://checkout.paymentspring.com/js/paymentspring.js";
  //   script.async = true;

  //   document.body.appendChild(script);
  // }

  render() {
    return (
      <Container>
        <div className="row justify-content-center">
          <div className="col-sm">
            <div className="row padded-container justify-content-center ">
              <h2>Registry</h2>
            </div>
            <div className="row justify-content-center padown">
              <Button
                outline
                block
                size="lg"
                color="warning"
                target="_blank"
                href="https://www.amazon.com/wedding?ref_=nav_cs_wedding_registry_b5263cc331a445bda3dedd22ed75c137&_encoding=UTF8&tag=allamericandr-20&linkCode=ur2&linkId=500bf358a7121e0b4a02902b40931924&camp=1789&creative=9325"
                className="col-7">
                Amazon
              </Button>{" "}
            </div>

            <div className="row justify-content-center padown">
              <Button
                outline
                block
                size="lg"
                color="primary"
                href="https://www.zola.com/registry/marymackandrusty"
                className="col-7">
                Zola
              </Button>{" "}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
