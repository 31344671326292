import React, { Component } from "react";
import Iframe from "react-iframe";
import Tippy from "@tippyjs/react";

function CopyToClipboard(id) {
  var r = document.createRange();
  r.selectNode(document.getElementById(id));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
  return false;
}

export class TheWedding extends Component {
  static displayName = TheWedding.name;

  render() {
    return (
      <div className="padded-container">
        <div className="row">
          <h2>The Wedding</h2>
          <p className="">
            The ceremony will start at 5pm on the vista in front of the Historic
            Beadel House at Tall Timbers. Parking is available both to the right
            and the left of the fork in the road. There is a large parking lot
            to the left in front of the Research building. There is also parking
            to the right – follow the gravel road and parking will be available
            in front of the small yellow barn.
          </p>
        </div>

        <div className="container">
          <div className="padown">
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11216.06364288026!2d-84.21992171567075!3d30.654648936676978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfce0b01adff7d20f!2sTall%20Timbers!5e0!3m2!1sen!2sus!4v1588287124752!5m2!1sen!2sus"
              width="100%"
              height="400"
              display="initial"
              allowFullScreen
            />
          </div>
        </div>

        <div className="row d-block padown">
          <h2>
            Location <br />
          </h2>
          <div>
            <Tippy content="Copied" hideOnClick={"toggle"} trigger="click">
              <p
                onClick={() => CopyToClipboard("wedaddress")}
                id="wedaddress"
                title="Tap or Click to Copy">
                Tall Timbers Reseach Station & Land Conservancy <br />
                13093 Henry Beadel Rd
                <br /> Tallahassee, FL 32312
              </p>
            </Tippy>
          </div>
        </div>

        <div className="row d-block padown">
          <h2 className="h2">Reception</h2>

          <p>
            The reception will immediately follow the wedding.
            <br />{" "}
          </p>

          <p>
            <strong>*Important note about Uber/Lyft:</strong> Tall Timbers is in
            the country, about 18 miles north of the hotels we reserved. While
            Uber/Lyft will come and pick you up, it is not guaranteed. Consider
            scheduling an Uber beforehand to ensure you can get home safely.
            Also consider ride-sharing to defray costs.
          </p>
        </div>
      </div>
    );
  }
}
export default TheWedding;
