import React from "react";
import { Col, Row, UncontrolledCarousel } from "reactstrap";
import pic1 from "../img/IMG_0946.JPG";
import pic2 from "../img/IMG_0532.JPG";
import pic3 from "../img/IMG_0604.JPG";
import pic4 from "../img/IMG_0905.JPG";
import pic5 from "../img/IMG_1238.JPG";
import pic6 from "../img/IMG_1323.JPG";
import pic7 from "../img/IMG_1889.JPG";
import pic8 from "../img/IMG_1803.JPG";
import pic9 from "../img/IMG_1823.JPG";
import pic11 from "../img/IMG_1851.JPG";
import pic12 from "../img/IMG_2429.JPG";
import pic13 from "../img/IMG_2573.JPG";
import pic14 from "../img/IMG_366c.JPG";
import pic15 from "../img/IMG_4485.JPG";
import pic16 from "../img/IMG_7874.JPG";
import pic17 from "../img/IMG_8289.JPG";
import pic18 from "../img/IMG_8295.JPG";
import pic19 from "../img/IMG_8643.JPG";
import pic20 from "../img/IMG_8916.JPG";
import pic10 from "../img/IMG_F69F.JPG";

const items = [
  {
    src: pic1,
    altText: "Slide 1",

    key: "1",
  },
  {
    src: pic2,
    altText: "Slide 2",

    key: "2",
  },
  {
    src: pic3,
    altText: "Slide 3",

    key: "3",
  },
  {
    src: pic4,
    altText: "Slide 4",

    key: "4",
  },
  {
    src: pic5,
    altText: "Slide 5",

    key: "5",
  },
  {
    src: pic6,
    altText: "Slide 6",

    key: "6",
  },
  {
    src: pic7,
    altText: "Slide 7",

    key: "7",
  },
  {
    src: pic8,
    altText: "Slide 8",

    key: "8",
  },
  {
    src: pic9,
    altText: "Slide 9",

    key: "9",
  },
  {
    src: pic10,
    altText: "Slide 10",

    key: "10",
  },
  {
    src: pic11,
    altText: "Slide 11",

    key: "11",
  },
  {
    src: pic12,
    altText: "Slide 12",

    key: "12",
  },
  {
    src: pic13,
    altText: "Slide 13",

    key: "13",
  },
  {
    src: pic14,
    altText: "Slide 14",

    key: "14",
  },
  {
    src: pic15,
    altText: "Slide 15",

    key: "15",
  },
  {
    src: pic16,
    altText: "Slide 16",

    key: "16",
  },
  {
    src: pic17,
    altText: "Slide 17",

    key: "17",
  },
  {
    src: pic18,
    altText: "Slide 18",

    key: "18",
  },
  {
    src: pic19,
    altText: "Slide 19",

    key: "19",
  },
  {
    src: pic20,
    altText: "Slide 20",

    key: "20",
  },
];

// const images = [
//   { src: "../img/wedpics/marrus_eng-1.jpg", alt: "Your description here 1" },
//   { src: "../img/wedpics/marrus_eng-2.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-3.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-4.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-5.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-6.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-20.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-21.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-22.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-23.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-24.jpg", alt: "Your description here 2" },
//   { src: "../img/wedpics/marrus_eng-25.jpg", alt: "Your description here 2" },
// ];

// export const Pics = () => (
//   <div className="container">
//     <div className="container">
//       {images.map(function (imageProps) {
//         return (
//           <div key={imageProps.src}>
//             <img src={imageProps.src} alt={imageProps.alt} />
//           </div>
//         );
//       })}
//     </div>
//   </div>
// );

export const Pics = () => (
  <UncontrolledCarousel
    items={items}
    className="container-md col-lg-9 centered"
  />
);

export default Pics;
