import React, { Component } from "react";
import Iframe from "react-iframe";


export class Travel extends Component {
    static displayName = Location.name;

    render() {
        return (

            <div className="padded-container">

                <div className="row d-block">
                    <h2 >Travel</h2>
                    <p >
                        We have reserved blocks at two hotels. Don’t forget to enter the discount code
                        (which is different for each hotel) to get wedding pricing.
                        </p>
                </div>

                <div className="row padown">
                    <p className="">
                        Book using this link: &#8200;
                        <a className="" href="https://hiltongardeninn.hilton.com/en/gi/groups/personalized/T/TLHTLGI-GSW-20210507/index.jhtml?WT.mc_id=POG">
                            Hotel Booking
                            </a>
                    </p>
                </div>

                <div className="row padown">
                    <p className="">
                        Hilton Garden Inn Discout Code = <strong>GSW</strong>
                    </p>
                </div>

                <div className="container row">
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55004.278750015415!2d-84.27559911643714!3d30.499331899999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ec58b67f167b49%3A0x2623da13138f4ec0!2sHilton%20Garden%20Inn%20Tallahassee!5e0!3m2!1sen!2sus!4v1613883684400!5m2!1sen!2sus" width="100%" height="400" display="initial" allowFullScreen />
                </div>

                <div className="row d-block padown">
                    <p >
                        Hampton Inn Discout Code = <strong>GWS</strong></p>
                </div>
                <div className="container row">
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110050.25611686285!2d-84.38393343044889!3d30.462441075304437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ec58b5fc1f1eb1%3A0x101a1cd80dc9c97c!2sHampton%20Inn%20%26%20Suites%20Tallahassee%20I-10-Thomasville%20Rd!5e0!3m2!1sen!2sus!4v1613883625373!5m2!1sen!2sus" width="100%" height="400" display="initial" allowFullScreen />
                </div>


            </div>
        );
    }
}
