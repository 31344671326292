import React, { Component } from 'react';

import MMR4 from './MMR4.png';


export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div className="container" >

                 
                      <img className="mx-auto d-block hov fade-in img-fluid padown" src={MMR4} alt="MaryMackandRusty"  >

                    </img>
                
                
                
      </div>
    );
  }
}
