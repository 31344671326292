import React, { Component } from "react";
import Kelly from "../img/kelly_mmg.jpg";
import RyanS from "../img/ryan_wedding_site.jpeg";
import DuaneD from "../img/duaneD.jpg";
import RitchieS from "../img/ritchieS.jpg";
import AndrewH from "../img/andrewH.jpg";
import RichardJ from "../img/richardJ2.jpg";
import JoshA from "../img/joshA.jpg";
import SophieG from "../img/sophie-ryan-boulder.jpg";
import AndrewC from "../img/andrew1.jpg";
import SarahS from "../img/sarah_sheehan.PNG";
import KevinJ from "../img/kev.jpg";
import EvanB from "../img/evanB.jpg";
import RyanSchrod from "../img/ryanSchrod.jpg";

export class WedParty extends Component {
  render() {
    return (
      <div className="padded-container">
        <div className="row d-block">
          <h2>Wedding Party</h2>
        </div>

        <div className="card-columns">
          <div className="card shadow">
            <img className="card-img-top" src={SophieG} alt="SophieG" />
            <div className="card-body">
              <h5 className="card-title">Sophie Gray</h5>
              <p className="card-text">
                Mary Mack's sister and partner in crime since 1993. Sophie is
                best known for her sharp wit, her hilarious misadventures, being
                quick to laugh, toilet naps, and for her love and support as the
                most wonderful sister - I would truly be lost without her.
                Pro-tip: If she seems aggressive or angry, try feeding her
                first.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Maid of Honor</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={Kelly} alt="Kelly" />
            <div className="card-body">
              <h5 className="card-title">Kelly Ledford Chase</h5>
              <p className="card-text">
                Kelly was an undergraduate classmate of Rusty's who moved to
                Tallahassee to pursue her Master's degree in Anthropology at FSU
                shortly after we started dating. We instantly became friends and
                have shared clothes, laughs, and hangovers; she has been my
                sister in Tallahassee for the past 4 years. She can be
                identified by her contagious laugh, her welcoming nature, and
                fabulous hair. She has a completely justified aversion to Gin
                Slushsies (thanks Hurricane Hermine), her dance moves, and her
                ability to rap every word to most Childish Gambino songs. She is
                married to bridesman Andrew Chase, and we're so lucky that they
                live right down the street from us!
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Bride's Maid</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={RyanS} alt="RyanS" />
            <div className="card-body">
              <h5 className="card-title">Ryan Shalley</h5>
              <p className="card-text">
                Ryan is one of the many beloved "Gray cousins" who is closest to
                my age. We navigated high school and college together and still
                fall apart laughing over stories from that time. She shares my
                love of trashy reality tv and we often finish the other’s
                thoughts. She is known for her ability to find a discount on
                anything, her charisma, her sass, and her ability to turn any
                stranger into a friend.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Bride's Maid</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={SarahS} alt="SarahS" />
            <div className="card-body">
              <h5 className="card-title">Sarah Sheehan</h5>
              <p className="card-text">
                I met Sarah and her partner Shawn on a now-infamous tubing trip
                for Kelly's birthday, and I have not known a moment of peace
                since. An archaeologist, when Sarah isn't traveling for work she
                can most often be found either pranking or feeding us. She is
                also known for affectionately calling every pet "Choochie", wild
                karaoke performances, and her ability to become instant friends
                with everyone she meets. She is a daily ray of sunshine in our
                lives.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Bride's Maid</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={KevinJ} alt="KevinJ" />
            <div className="card-body">
              <h5 className="card-title">Kevin Jacobs</h5>
              <p className="card-text">
                Kevin and I went to high school together but became much closer
                when I moved to Tallahassee for work. Kevin was kind enough to
                take me under his wing when I was a new Tallahasseean and one
                could argue he's the reason I even met Rusty...my initial job
                contract was for 3 months, but Kevin repeatedly encouraged me to
                stay in town rather than take off to less exciting places like
                Hawaii and Arizona. Legend has it Kevin has never met a neutral
                paint palette he doesn't like and he single-handedly brought
                suspenders back into the 21st century.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Bridesman</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={AndrewC} alt="AndrewC" />
            <div className="card-body">
              <h5 className="card-title">Andrew Chase</h5>
              <p className="card-text">
                Andrew is the first friend I made it Tallahassee. He also works
                at Tall Timbers where his main job is burning the whole place
                down (literally). Despite his manly appearance, he is quite shy
                at first. He is known for his love of Miller Lite, Led Zeppelin,
                pyromania, brute strength and generosity. Originally from
                Boston, he shows no outward signs of the characteristic cold
                Northern persona, but his wicked accent often comes out late
                into the night.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Bridesman</small>
            </div>
          </div>
        </div>

        <div className="card-columns">
          <div className="card shadow">
            <img className="card-img-top" src={DuaneD} alt="DuaneD" />
            <div className="card-body">
              <h5 className="card-title">Duane Duke</h5>
              <p className="card-text">
                Duane (aka Duke) met Rusty in the Army and they served together
                in Iraq. Once they got back to the States, they set out on many
                more adventures, including enrolling in college and joining a
                fraternityHe eventually rejoined the Army, but was sure to visit
                in between his numerous deployments to Iraq and Afghanistan.
                He's known for being the life of the party and is always on the
                go. Just know that when you accompany him on an errand, you
                never know where you might end up.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Best Man</small>
            </div>
          </div>
          <div className="card shadow">
            <img className="card-img-top" src={RitchieS} alt="RitchieS" />
            <div className="card-body">
              <h5 className="card-title">Ritchie Lee Simmons</h5>
              <p className="card-text">
                Ritchie is Rusty's little brother! He joined Rusty down in
                Tallahassee years ago after attending college up in Tennessee.
                Ritchie is an artist, taking heavily after their father. He
                loves cooking and fishing, but excels at writing. You'll know
                Ritchie by his stylish ‘stache and his unrivaled "cool"
                demeanor.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>
          <div className="card shadow">
            <img className="card-img-top" src={AndrewH} alt="AndrewH" />
            <div className="card-body">
              <h5 className="card-title">Andrew Hutt</h5>
              <p className="card-text">
                Ol Hutt met Rusty when they joined the fraternity. After Duke
                left Rusty and Hutt to go back to War, they figured they ought
                to stay in school. They moved out of the frat house, settled in
                a nice home, and finished their science degrees. Hutt found his
                way back to Rusty and moved to Florida a couple of years ago.
                Just look for the groomsman in a ball cap and you'll find Hutt.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>

          <div className="card shadow">
            <img className="card-img-top" src={RichardJ} alt="RichardJ" />
            <div className="card-body">
              <h5 className="card-title">Richard Johnson Jr</h5>
              <p className="card-text">
                Richard is Rusty's oldest friend. They met in the 5th grade and
                had many great years all throughout their coming of age. They
                started out playing soccer and went on to work several jobs
                together. When Rusty moved down to Florida from Tennessee,
                Richard rode along and helped him find a place. Not only did
                Richard help Rusty move in, but he also helped find friends! If
                you see Richard out there, you can call him Dick Dick.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>
          <div className="card shadow">
            <img className="card-img-top" src={JoshA} alt="JoshA" />
            <div className="card-body">
              <h5 className="card-title">Joshua Adams</h5>
              <p className="card-text">
                Josh was Rusty's favorite roommate and best friend here in
                Tallahassee. While needing a place to stay, Rusty moved in with
                Josh before they even met. They've spent many hours hunting and
                fishing along the Florida coast and have even been lost out to
                sea together. Josh recently started a family and welcomed a
                healthy young American to this world. You'll find Josh sporting
                tie dye and a cop stache.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>
          <div className="card shadow">
            <img className="card-img-top" src={EvanB} alt="EvanB" />
            <div className="card-body">
              <h5 className="card-title">Evan Blythe</h5>
              <p className="card-text">
                Rusty and Evan met while having wild conversations over a
                neighboring fence. He is a master conversationalist and will
                never leave you unamused. Although Evan and Josh were already
                best friends, they welcomed me into their fold and we roomed
                with each other for years. Evan then went out West to be a land
                man. This past year, Evan found his way back to Florida and has
                been here in quarantine ever since. So give him a big hug for
                me.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>
          <div className="card shadow">
            <img className="card-img-top" src={RyanSchrod} alt="Shrodester" />
            <div className="card-body">
              <h5 className="card-title">Ryan Schroeder</h5>
              <p className="card-text">
                Ryan is Sophie’s fiance and we are so excited to be creating a
                new extended family with him. He is an avid reader, skier, and
                sports enthusiast and is always up for anything. Eternally
                upbeat, jovial, and warm, if you’re looking for Ryan the dance
                floor is a good place to start.
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Groomsman</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
