import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import MMR from "./MMRLOGO.png";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 fixed-top bg-white"
          light
        >
          <Container>
            <NavbarBrand tag={Link} to="/">
              <img
                className="brand"
                src={MMR}
                alt="MM and Rusty"
                style={{ width: 150 }}
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse text-center"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul
                className="navbar-nav flex-grow"
                onClick={!this.state.collapsed}
              >
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/"
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/our-story"
                  >
                    Our Story
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/wedding"
                  >
                    Wedding
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/travel"
                  >
                    Travel
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/pics"
                  >
                    Pics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/wedparty"
                  >
                    Wedding Party
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/rsvp"
                  >
                    RSVP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark font-weight-bold"
                    to="/gifts"
                  >
                    Gifts
                  </NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
