import React, { Component } from "react";
import { Route } from "react-router";
import { OurStory } from "./components/OurStory";
import { TheWedding } from "./components/TheWedding";
import { Travel } from "./components/Travel";
import { Gifts } from "./components/Gifts";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { Pics } from "./components/Pics";
import { WedParty } from "./components/WeddingParty";
import { RSVP } from "./components/RSVP";

import './tippy.css';
import "./custom.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Route exact path="/" component={Home} />
        <Route exact path="/our-story" component={OurStory} />
        <Route exact path="/wedding" component={TheWedding} />
        <Route exact path="/travel" component={Travel} />
        <Route exact path="/gifts" component={Gifts} />
        <Route exact path="/pics" component={Pics} />
        <Route exact path="/rsvp" component={RSVP} />
        <Route exact path="/wedparty" component={WedParty} />

      </Layout>
    );
  }
}
