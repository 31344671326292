import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { ScrollTop } from "./ScrollTop";


export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <div>
          <NavMenu />
        </div>
        <div>
          <Container id="opac">{this.props.children}</Container>
        </div>
        <div>
          <ScrollTop />
        </div>
      </div>
    );
  }
}
